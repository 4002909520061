import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminPage from './pages/admin-page'; // Adjust the path if necessary

function App() {
    return (
        <Router basename="/signal-generator-admin">
            <Routes>
                <Route path="/" element={<AdminPage />} />
            </Routes>
        </Router>
    );
}

export default App;

