import config from '../config/react-app-config';

export const FETCH_LOGS_SUCCESS = 'FETCH_LOGS_SUCCESS';
export const DELETE_LOG_SUCCESS = 'DELETE_LOG_SUCCESS';

export const fetchLogsSuccess = (logs) => ({
    type: FETCH_LOGS_SUCCESS,
    payload: logs,
});

export const deleteLogSuccess = (log) => ({
    type: DELETE_LOG_SUCCESS,
    payload: log,
});

export const fetchLogs = () => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${config.production.serverUrl}/fetch-all-logs`);
            if (!response.ok) {
                throw new Error('Failed to fetch logs');
            }
            const jsonData = await response.json();
            dispatch(fetchLogsSuccess(jsonData.data));
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
    };
};

export const deleteLog = (logToDelete) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${config.production.serverUrl}/delete-log`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(logToDelete),
            });
            if (!response.ok) {
                throw new Error('Failed to delete log');
            }
            const jsonData = await response.json();
            dispatch(deleteLogSuccess(jsonData.data)); // Dispatch the log to be deleted
        } catch (error) {
            console.error('Error deleting log:', error);
        }
    };
};




