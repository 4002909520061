import { combineReducers } from 'redux';
import { FETCH_LOGS_SUCCESS, DELETE_LOG_SUCCESS } from './actions';

const initialLogsState = {
    logs: [],
};

const logsReducer = (state = initialLogsState, action) => {
    switch (action.type) {
        case FETCH_LOGS_SUCCESS:
            return {
                ...state,
                logs: action.payload,
            };
        case DELETE_LOG_SUCCESS:
            return {
                ...state,
                logs: state.logs.filter(log => log.timestamp !== action.payload.timestamp),
            };
        default:
            return state;
    }
};

// Combine reducers into a rootReducer
const rootReducer = combineReducers({
    logs: logsReducer,
});

export default rootReducer;
